body {
  font-family: 'Roboto', sans-serif;
}
/* This class is here due to a bug in the react-big-calendar monthly view. the original class was set to height 100%, and that caused it to overflow its container */
div.rbc-month-view {
  height: auto;
}
div.rbc-calendar {
  height: 93%;
}
div.rbc-current-time-indicator {
  height: 2px;
  background-color: rgb(107, 120, 143);
}
.newMaterial {
  display: inline-block;
  max-width: 100%;
  padding: 12px;
  break-inside: avoid;
}
.printOnlyWrapper,
.printOnlySignatureBox {
  display: none;
}
.sideScrollingContainer {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.sideScrollingContainer > button {
  flex: 0 0 auto;
}
.slateGreySVG {
  /* this filter is put over the svg to change its color
  new filters can be generated from this site https://codepen.io/sosuke/pen/Pjoqqp */
  filter: invert(46%) sepia(27%) saturate(249%) hue-rotate(169deg) brightness(101%) contrast(93%);
}
.greySVG {
  opacity: 0.54;
}
.printableLinks{
  color: #0000EE;
  cursor: pointer;
}
@media print {
  .hidePrint {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
    line-height: 0pt !important;
    white-space: nowrap;
  }
  .printableLinks{
    color: inherit
  }
  div.MuiTooltip-popper {
    display: none !important;
  }
  .printOnlyWrapper {
    display: block !important;
    margin: -24px;
  }
  .printOnlySignatureBox {
    display: inline-block !important;
  }
  .firstLabel {
    margin-top: -70px;
  }

  .firstPage {
    margin: -55px 0 0px 0 !important;
  }
  .firstTable {
    position: relative;
  }
  .secondTable {
    height: 975px;
    position: relative;
  }
  .newPage {
    padding-top: 10pt;
  }
  .smallPrintFont {
    font-size: 0.65rem !important;
  }
  /*only works in webkit browsers (Chrome)*/
  body {
    -webkit-print-color-adjust: exact !important;
  }
}
